import { useMemo } from 'react';

export default function useScrollbarSize(): { width: number; height: number } {
  return useMemo(() => {
    const isBrowser = typeof window === 'object';
    if (!isBrowser) {
      return {
        width: 0,
        height: 0,
      };
    }
    const node = document.createElement('div');
    node.style.width = '100px';
    node.style.height = '100px';
    node.style.position = 'absolute';
    node.style.top = '-100000px';
    node.style.overflow = 'scroll';
    node.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(node);

    const dimensions = {
      height: node.offsetHeight - node.clientHeight,
      width: node.offsetWidth - node.clientWidth,
    };
    document.body.removeChild(node);
    return dimensions;
  }, []);
}
