import React, { useCallback, useRef, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';

import styles from './App.scss';
import useWindowSize from 'hooks/useWindowSize';
import useScrollbarSize from 'hooks/useScrollbarSize';

import { totalWidth, tileHeight, tiles } from 'tiles';

interface Props {}

const App: React.FC<Props> = props => {
  const listRef = useRef<List | null>(null);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { height: scrollbarHeight } = useScrollbarSize();
  const scaleFactor = (windowHeight - scrollbarHeight) / tileHeight;
  const scaledTileWidth = (totalWidth / tiles.length) * scaleFactor - 1;
  const scaledTileHeight = tileHeight * scaleFactor;

  const Column = useCallback(
    ({ index, style }) => {
      const tile = tiles[index];
      return (
        <div style={style}>
          <img src={tile.fileName} width={tile.width * scaleFactor} height={scaledTileHeight} />
        </div>
      );
    },
    [scaleFactor, scaledTileHeight]
  );

  useEffect(() => {
    const midPoint = (scaledTileWidth * tiles.length) / 2;
    const scrollLeft = Math.max(0, midPoint - windowWidth / 2);
    listRef.current!.scrollTo(scrollLeft);
  }, []);

  return (
    <div className={styles.root}>
      <List
        className={styles.list}
        ref={listRef}
        height={windowHeight}
        itemCount={tiles.length}
        itemSize={scaledTileWidth}
        layout="horizontal"
        width={windowWidth}
      >
        {Column}
      </List>
    </div>
  );
};
export default App;
