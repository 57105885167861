import { Tile } from 'types/tiles';

import tile000 from './tile-000.jpg';
import tile001 from './tile-001.jpg';
import tile002 from './tile-002.jpg';
import tile003 from './tile-003.jpg';
import tile004 from './tile-004.jpg';
import tile005 from './tile-005.jpg';
import tile006 from './tile-006.jpg';
import tile007 from './tile-007.jpg';
import tile008 from './tile-008.jpg';
import tile009 from './tile-009.jpg';
import tile010 from './tile-010.jpg';
import tile011 from './tile-011.jpg';
import tile012 from './tile-012.jpg';
import tile013 from './tile-013.jpg';
import tile014 from './tile-014.jpg';
import tile015 from './tile-015.jpg';
import tile016 from './tile-016.jpg';
import tile017 from './tile-017.jpg';
import tile018 from './tile-018.jpg';
import tile019 from './tile-019.jpg';
import tile020 from './tile-020.jpg';
import tile021 from './tile-021.jpg';
import tile022 from './tile-022.jpg';
import tile023 from './tile-023.jpg';
import tile024 from './tile-024.jpg';
import tile025 from './tile-025.jpg';
import tile026 from './tile-026.jpg';
import tile027 from './tile-027.jpg';
import tile028 from './tile-028.jpg';
import tile029 from './tile-029.jpg';
import tile030 from './tile-030.jpg';
import tile031 from './tile-031.jpg';
import tile032 from './tile-032.jpg';
import tile033 from './tile-033.jpg';
import tile034 from './tile-034.jpg';
import tile035 from './tile-035.jpg';
import tile036 from './tile-036.jpg';
import tile037 from './tile-037.jpg';
import tile038 from './tile-038.jpg';
import tile039 from './tile-039.jpg';
import tile040 from './tile-040.jpg';
import tile041 from './tile-041.jpg';
import tile042 from './tile-042.jpg';
import tile043 from './tile-043.jpg';
import tile044 from './tile-044.jpg';
import tile045 from './tile-045.jpg';
import tile046 from './tile-046.jpg';
import tile047 from './tile-047.jpg';
import tile048 from './tile-048.jpg';
import tile049 from './tile-049.jpg';
import tile050 from './tile-050.jpg';
import tile051 from './tile-051.jpg';
import tile052 from './tile-052.jpg';
import tile053 from './tile-053.jpg';
import tile054 from './tile-054.jpg';
import tile055 from './tile-055.jpg';
import tile056 from './tile-056.jpg';
import tile057 from './tile-057.jpg';
import tile058 from './tile-058.jpg';
import tile059 from './tile-059.jpg';
import tile060 from './tile-060.jpg';
import tile061 from './tile-061.jpg';
import tile062 from './tile-062.jpg';
import tile063 from './tile-063.jpg';

export const tileHeight = 1600;
export const totalWidth = 10200;
export const tiles: Array<Tile> = [
  {
    tileName: 'tile000',
    fileName: tile000,
    width: 159,
  },
  {
    tileName: 'tile001',
    fileName: tile001,
    width: 159,
  },
  {
    tileName: 'tile002',
    fileName: tile002,
    width: 159,
  },
  {
    tileName: 'tile003',
    fileName: tile003,
    width: 159,
  },
  {
    tileName: 'tile004',
    fileName: tile004,
    width: 159,
  },
  {
    tileName: 'tile005',
    fileName: tile005,
    width: 159,
  },
  {
    tileName: 'tile006',
    fileName: tile006,
    width: 159,
  },
  {
    tileName: 'tile007',
    fileName: tile007,
    width: 159,
  },
  {
    tileName: 'tile008',
    fileName: tile008,
    width: 159,
  },
  {
    tileName: 'tile009',
    fileName: tile009,
    width: 159,
  },
  {
    tileName: 'tile010',
    fileName: tile010,
    width: 159,
  },
  {
    tileName: 'tile011',
    fileName: tile011,
    width: 159,
  },
  {
    tileName: 'tile012',
    fileName: tile012,
    width: 159,
  },
  {
    tileName: 'tile013',
    fileName: tile013,
    width: 159,
  },
  {
    tileName: 'tile014',
    fileName: tile014,
    width: 159,
  },
  {
    tileName: 'tile015',
    fileName: tile015,
    width: 159,
  },
  {
    tileName: 'tile016',
    fileName: tile016,
    width: 159,
  },
  {
    tileName: 'tile017',
    fileName: tile017,
    width: 159,
  },
  {
    tileName: 'tile018',
    fileName: tile018,
    width: 159,
  },
  {
    tileName: 'tile019',
    fileName: tile019,
    width: 159,
  },
  {
    tileName: 'tile020',
    fileName: tile020,
    width: 159,
  },
  {
    tileName: 'tile021',
    fileName: tile021,
    width: 159,
  },
  {
    tileName: 'tile022',
    fileName: tile022,
    width: 159,
  },
  {
    tileName: 'tile023',
    fileName: tile023,
    width: 159,
  },
  {
    tileName: 'tile024',
    fileName: tile024,
    width: 159,
  },
  {
    tileName: 'tile025',
    fileName: tile025,
    width: 159,
  },
  {
    tileName: 'tile026',
    fileName: tile026,
    width: 159,
  },
  {
    tileName: 'tile027',
    fileName: tile027,
    width: 159,
  },
  {
    tileName: 'tile028',
    fileName: tile028,
    width: 159,
  },
  {
    tileName: 'tile029',
    fileName: tile029,
    width: 159,
  },
  {
    tileName: 'tile030',
    fileName: tile030,
    width: 159,
  },
  {
    tileName: 'tile031',
    fileName: tile031,
    width: 159,
  },
  {
    tileName: 'tile032',
    fileName: tile032,
    width: 159,
  },
  {
    tileName: 'tile033',
    fileName: tile033,
    width: 159,
  },
  {
    tileName: 'tile034',
    fileName: tile034,
    width: 159,
  },
  {
    tileName: 'tile035',
    fileName: tile035,
    width: 159,
  },
  {
    tileName: 'tile036',
    fileName: tile036,
    width: 159,
  },
  {
    tileName: 'tile037',
    fileName: tile037,
    width: 159,
  },
  {
    tileName: 'tile038',
    fileName: tile038,
    width: 159,
  },
  {
    tileName: 'tile039',
    fileName: tile039,
    width: 159,
  },
  {
    tileName: 'tile040',
    fileName: tile040,
    width: 159,
  },
  {
    tileName: 'tile041',
    fileName: tile041,
    width: 159,
  },
  {
    tileName: 'tile042',
    fileName: tile042,
    width: 159,
  },
  {
    tileName: 'tile043',
    fileName: tile043,
    width: 159,
  },
  {
    tileName: 'tile044',
    fileName: tile044,
    width: 159,
  },
  {
    tileName: 'tile045',
    fileName: tile045,
    width: 159,
  },
  {
    tileName: 'tile046',
    fileName: tile046,
    width: 159,
  },
  {
    tileName: 'tile047',
    fileName: tile047,
    width: 159,
  },
  {
    tileName: 'tile048',
    fileName: tile048,
    width: 159,
  },
  {
    tileName: 'tile049',
    fileName: tile049,
    width: 159,
  },
  {
    tileName: 'tile050',
    fileName: tile050,
    width: 159,
  },
  {
    tileName: 'tile051',
    fileName: tile051,
    width: 159,
  },
  {
    tileName: 'tile052',
    fileName: tile052,
    width: 159,
  },
  {
    tileName: 'tile053',
    fileName: tile053,
    width: 159,
  },
  {
    tileName: 'tile054',
    fileName: tile054,
    width: 159,
  },
  {
    tileName: 'tile055',
    fileName: tile055,
    width: 159,
  },
  {
    tileName: 'tile056',
    fileName: tile056,
    width: 159,
  },
  {
    tileName: 'tile057',
    fileName: tile057,
    width: 159,
  },
  {
    tileName: 'tile058',
    fileName: tile058,
    width: 159,
  },
  {
    tileName: 'tile059',
    fileName: tile059,
    width: 159,
  },
  {
    tileName: 'tile060',
    fileName: tile060,
    width: 159,
  },
  {
    tileName: 'tile061',
    fileName: tile061,
    width: 159,
  },
  {
    tileName: 'tile062',
    fileName: tile062,
    width: 159,
  },
  {
    tileName: 'tile063',
    fileName: tile063,
    width: 159,
  },
]